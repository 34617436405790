.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
}

.header {
  margin-top: 64px;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: gray;
}

.bio {
  text-align: center;
  color: gray;
  margin-top: 16px;
}

.joinSpaceButton {
  cursor: pointer;
  margin-top: 16px;
  padding: 28px;
  border: 0;
  border-radius: 15px;
  background: linear-gradient(#00d0ff, transparent);
}

/* 00d0ff */
