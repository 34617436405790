body,
html {
  height: 100%;
}

.bg {
  background-image: url("utils/space-bg-2.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
